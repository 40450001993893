.headerCont{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 62px;
    background: #001529;
}

.ant-layout-sider-children{
    height: 100vh;
}

.site-layout{
    background: white;
}

.ant-menu-vertical .ant-menu-submenu-title{
    color: #001529 !important;
}

.menu{
    background: transparent;
    border-bottom: none !important;
}

.ant-menu-item-selected, .ant-menu-submenu-selected{
    color: white !important;
    background: #006d77 !important;
    border-bottom: 2px solid white !important;
}

.ant-menu-item-selected > a, .ant-menu-submenu-title{
    color: #f0f2f5 !important;
}

.ant-menu-item{
    color: #006d77;
}

.ant-menu-item:hover{
    background: transparent !important;
    border-bottom: 2px solid #006d77 !important;
}

.ant-menu-item > a:hover{
    color: #006d77 !important;
}

.full_width{
    width: 100%;
}

.text_center{
    text-align: center;
}

.text_right{
    text-align: right;
}

.title{
    text-align: center;
    color: #006d77;
}

.filter_field{
    padding: 5px;
}

.with_space{
    margin-bottom: 10px;
}

.add_buttom, .add_buttom:hover{
    background-color: green;
    color: white;
}

.add_buttom:hover{
    opacity: 0.55;
}

.logo{
    text-align: center;
}

.logo > a > img{
    width: 54px;
}

.ind_card_big{
    border: solid 1px #41a9bd;
    text-align: center;
}

.ind_card_big > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title{
    font-size: 30px;
    color: white;
}

.ind_card_big > .ant-card-head{
    background-color: #41a9bd;
}

.ind_card_big > .ant-card-body{
    font-size: 45px;
    height: inherit !important;
    color: #502878;
}

#exportBtn{
    margin-left: 5px;
  }
.ant-card-cover{
    padding: 15px;
}

.ant-card-cover img{
    max-width: 120px;
    display: block;
    margin: auto;
}

.ant-card-meta-title > center > a{
    white-space: break-spaces;
}

.ant-card-body{
    padding: 5px !important;
    height: 50px;
}

.female_card {
    border: 2px solid pink !important
}

.female_card > .ant-card-cover img{
    transform: scaleX(-1);
}

.male_card {
    border: 2px solid blue !important
}

.void_card{
    height: 109px;
    background-color: #fafafa;
}

.edit_hover{
    padding-top: 10%;
    text-align: center;
    position: absolute;
    top: 2%;
    right: 0;
    bottom: 0;
    left: 3%;
    z-index: 1000;
    width: 94%;
    height: 96%;
    background-color: rgba(0, 0, 0, 0.45);
}

.numberCard > .ant-card-head{
    text-align: center;
    font-size: 24px;
    height: 95px;
}

.numberCard > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title{
    white-space: pre-line !important;
}

.numberCard > .ant-card-body{
    text-align: center;
    font-size: 36px;
    padding: 20px !important;
    height: auto !important;
}